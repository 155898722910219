import { Flex } from '@stars-ecom/shared-atoms-ui'
import { AccountAddresses, AccountLayout } from '@stars-ecom/shared-organisms-ui'
import React from 'react'
import { Helmet } from 'react-helmet'

import { siteFormatter } from '../utils/metaTitleFormatter'

const AccountAddressesPage = () => {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      pb="15px"
      backgroundColor="#f6f3f1"
      w="100%">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={`Mes adresses de livraison - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}
        />
        <meta name="robots" content="noindex" />
        <title>{`Mes adresses de livraison - ${siteFormatter(
          process.env.GATSBY_API_WEBSITE
        )}`}</title>
      </Helmet>
      <AccountLayout>
        <AccountAddresses />
      </AccountLayout>
    </Flex>
  )
}

AccountAddressesPage.pageType = 'account'

export default AccountAddressesPage
